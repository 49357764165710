export default {
  init() {
    // console.log("home");

    // Video front page 
    jQuery(document).ready(function() { 
      if (jQuery('.banner').length) {
        var window_width_front = jQuery(window).width(); 
        var video_front_page = jQuery('.videoSlide_front');
        var url_small_front_page = jQuery('.videoSmall_front').text();
        var url_big_front_page = jQuery('.videoBig_front').text();
      
        if (window_width_front < 1000) {
          video_front_page.append("<source src='" + url_small_front_page + "' type='video/mp4'>");
        } else {
          video_front_page.append("<source src='" + url_big_front_page + "' type='video/mp4'>");
        }
      }
    });
     
    // Redirect to projects page when scroll to bottom of front page
    // var projecturl = siteUrl + '/projects'; 

    // jQuery(window).scroll(function() {
    //   if(jQuery(window).scrollTop() + jQuery(window).height() == jQuery(document).height()) {
    //     window.location.replace(projecturl);
    //   }
    // });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
