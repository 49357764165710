// import Swiper from 'swiper';
// import "./javascripts/vendors/swiper.min.js";

export default {
  init() {
    // JavaScript to be fired on all pages
    // console.log('common');
  },
  finalize() {
    // console.log('common finalize');
    
    jQuery(window).ready(function() {
      // Open menu
      jQuery('.menu-toggle').on('click', function() {
        jQuery('body').toggleClass('menu-open');

        // Fade in items one by one
        jQuery('.menu-wrapper li').each(function (i) {
          var item = jQuery(this);
          setTimeout(function () {
            item.toggleClass('loaded', !item.hasClass('loaded'));
          }, 90 * i);
        });
    
      });
    
      // Close menu on big screen
      if (jQuery(window).width() > 991) {
        jQuery('body').removeClass('menu-open');
      }
    });

    // Add scroll class to header
    function checkScroll(){ 
      if (jQuery(window).scrollTop() >= 150) {
        jQuery('header').addClass('scrolled');
      } else {
        jQuery('header').removeClass('scrolled');
      }
    }

    jQuery(document).ready(function() {
      checkScroll();
      jQuery(window).scroll(checkScroll);
    });

    // Video slider 
    jQuery(document).ready(function() { 
      if (jQuery('.videoSlide').length) {
        var window_width = jQuery(window).width(); 
        
        jQuery('.videoContainer').each(function(i, obj) {
          var video = jQuery(this).find('.videoSlide');
          var url_small = jQuery(this).find('.videoSmall').text();
          var url_big = jQuery(this).find('.videoBig').text();

          if (window_width < 1000) {
            video.append("<source src='" + url_small + "' type='video/mp4'>");
          } else {
            video.append("<source src='" + url_big + "' type='video/mp4'>");
          }
        });

      }
    });
    
    // JavaScript to be fired on all pages, after page specific JS is fired
    jQuery(document).ready(function() { 
      var swiper = new Swiper('.swiper-container', {        
        loop: true,
        preloadImages: false,
        lazyLoading: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      });
    });

  },
};
